import {
  PalipediaEquipmentWithDocuments,
  mapFleetMonitorPalipediaToPalipediaEquipmentWithDocuments,
} from '@features/palipedia';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { DocumentsResponse } from '@connected/data-access/fleet-service';
import { DocumentsActions } from './documents.actions';

const key = 'documents';

export interface DocumentsState {
  documents: PalipediaEquipmentWithDocuments | null;
  isDocumentsLoading: boolean;
  hasDocumentsFailed: boolean;

  customerDocuments: DocumentsResponse | null;
  isCustomerDocumentsLoading: boolean;
  hasCustomerDocumentsFailed: boolean;

  isUploadPending: boolean;
  areDeletePending: { [key: string]: boolean };
}

export const initialState: DocumentsState = {
  documents: null,
  isDocumentsLoading: true,
  hasDocumentsFailed: false,

  customerDocuments: null,
  isCustomerDocumentsLoading: true,
  hasCustomerDocumentsFailed: false,

  isUploadPending: false,
  areDeletePending: {},
};

const _documentsReducer = createReducer(
  initialState,
  on(DocumentsActions.LoadDocuments, (state) => ({
    ...state,
    documents: initialState.documents,
    isDocumentsLoading: true,
    hasDocumentsFailed: false,
  })),
  on(DocumentsActions.LoadDocumentsSuccess, (state, { payload }) => ({
    ...state,
    documents:
      mapFleetMonitorPalipediaToPalipediaEquipmentWithDocuments(payload),
    isDocumentsLoading: false,
    hasDocumentsFailed: false,
  })),
  on(DocumentsActions.LoadDocumentsError, (state) => ({
    ...state,
    isDocumentsLoading: false,
    hasDocumentsFailed: true,
  })),

  on(DocumentsActions.LoadCustomerDocuments, (state) => ({
    ...state,
    customerDocuments: initialState.customerDocuments,
    isCustomerDocumentsLoading: true,
    hasCustomerDocumentsFailed: false,
  })),
  on(DocumentsActions.LoadCustomerDocumentsSuccess, (state, { payload }) => ({
    ...state,
    customerDocuments: payload,
    isCustomerDocumentsLoading: false,
    hasCustomerDocumentsFailed: false,
  })),
  on(DocumentsActions.LoadCustomerDocumentsError, (state) => ({
    ...state,
    isCustomerDocumentsLoading: false,
    hasCustomerDocumentsFailed: true,
  })),

  on(DocumentsActions.UploadCustomerDocuments, (state) => ({
    ...state,
    isUploadPending: true,
  })),
  on(DocumentsActions.UploadCustomerDocumentsSuccess, (state) => ({
    ...state,
    isUploadPending: false,
    isCustomerDocumentsLoading: true,
    hasCustomerDocumentsFailed: false,
  })),
  on(DocumentsActions.UploadCustomerDocumentsError, (state) => ({
    ...state,
    isUploadPending: false,
  })),

  on(DocumentsActions.DeleteCustomerDocuments, (state, payload) => ({
    ...state,
    areDeletePending: {
      ...state.areDeletePending,
      [payload.document_id]: true,
    },
  })),
  on(DocumentsActions.DeleteCustomerDocumentsSuccess, (state, payload) => ({
    ...state,
    areDeletePending: {
      ...state.areDeletePending,
      [payload.document_id]: false,
    },
    isCustomerDocumentsLoading: true,
    hasCustomerDocumentsFailed: false,
  })),
  on(DocumentsActions.DeleteCustomerDocumentsError, (state, { payload }) => ({
    ...state,
    areDeletePending: {
      ...state.areDeletePending,
      [payload]: false,
    },
  })),
);

export const DocumentsFeature = createFeature({
  name: key,
  reducer: _documentsReducer,
  extraSelectors: ({ selectDocuments }) => ({
    selectDocumentsImages: createSelector(
      selectDocuments,
      (documents) =>
        documents?.pictures?.map((pictures) => ({
          thumbnail: pictures.youtubeUrl || pictures.pictureUrl || undefined,
          src: pictures.youtubeUrl || pictures.pictureUrl || '',
          text: '',
          id: Number(pictures.id),
        })) || [],
    ),
  }),
});
