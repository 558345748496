import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  CustomerDocumentsService,
  EquipmentService,
} from '@connected/data-access/fleet-service';
import { Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { DocumentsActions } from './documents.actions';
import { SnackbarActions } from '@design-system/feature/snackbar';

@Injectable()
export class DocumentsEffects {
  private _actions$ = inject(Actions);

  LoadDocuments$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(DocumentsActions.LoadDocuments),
      switchMap(({ payload }) =>
        this._equipmentService.getHybrisEquipment(payload).pipe(
          map((data) =>
            DocumentsActions.LoadDocumentsSuccess({
              payload: data,
            }),
          ),
          catchError(() => [DocumentsActions.LoadDocumentsError()]),
        ),
      ),
    ),
  );

  LoadCustomerDocuments$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(
        DocumentsActions.LoadCustomerDocuments,
        DocumentsActions.UploadCustomerDocumentsSuccess,
        DocumentsActions.DeleteCustomerDocumentsSuccess,
      ),
      switchMap(({ payload }) =>
        this._customerDocumentService.getDocuments(payload).pipe(
          map((data) =>
            DocumentsActions.LoadCustomerDocumentsSuccess({
              payload: data,
            }),
          ),
          catchError(() => [DocumentsActions.LoadCustomerDocumentsError()]),
        ),
      ),
    ),
  );

  deleteCustomerDocuments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DocumentsActions.DeleteCustomerDocuments),
      switchMap((action) =>
        this._customerDocumentService
          .deleteDocuments(action.document_id, action.equipment_number)
          .pipe(
            mergeMap(() => [
              DocumentsActions.DeleteCustomerDocumentsSuccess({
                payload: action.equipment_number,
                document_id: action.document_id,
              }),
              SnackbarActions.ShowSuccessSnackbar({
                messageKey: 'fleet_monitor.messages.delete_message',
              }),
            ]),
            catchError(() => [
              DocumentsActions.DeleteCustomerDocumentsError({
                payload: action.equipment_number,
              }),
              SnackbarActions.ShowErrorSnackbar({
                messageKey: 'fleet_monitor.messages.delete_message_failed',
              }),
            ]),
          ),
      ),
    ),
  );

  uploadCustomerDocuments$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DocumentsActions.UploadCustomerDocuments),
      switchMap((action) =>
        this._customerDocumentService
          .uploadDocuments(
            action.equipment_number,
            undefined,
            action.files,
            'response',
          )
          .pipe(
            mergeMap(() => [
              SnackbarActions.ShowSuccessSnackbar({
                messageKey: 'fleet_monitor.messages.upload_message',
              }),
              DocumentsActions.UploadCustomerDocumentsSuccess({
                payload: action.equipment_number,
              }),
            ]),
            catchError((err) =>
              err?.status !== 403
                ? [
                    DocumentsActions.UploadCustomerDocumentsError(),
                    SnackbarActions.ShowErrorSnackbar({
                      messageKey:
                        'fleet_monitor.messages.upload_message_failed',
                    }),
                  ]
                : [
                    DocumentsActions.UploadCustomerDocumentsError(),
                    SnackbarActions.ShowErrorSnackbar({
                      messageKey:
                        'fleet_monitor.messages.upload_message_quota_failed',
                    }),
                  ],
            ),
          ),
      ),
    ),
  );

  constructor(
    private _equipmentService: EquipmentService,
    private _customerDocumentService: CustomerDocumentsService,
  ) {}
}
